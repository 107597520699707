import Sticky from 'sticky-js';
import ouibounce from 'ouibounce';
import hopscotch from 'hopscotch';
import 'scrollto.js';
import LazyLoad from 'vanilla-lazyload';
import slick from 'slick-carousel';
// import serialize from 'form-serialize';
// import format from 'date-format';
// import _ from 'lodash';

import '../helpers/jquery-viewport';
import emitter from '../emitter';
import definition from '../definition';
import api from './../api';
import { jsGoTo } from './helpers';
import cookieHelper from './../helpers/cookie-helper';
import favoritesHelper from '../helpers/favorites-helper';
import landingHelper from '../helpers/landing-helper';
import newsletterHelper from '../helpers/newsletter-helper';
import './jquery-scrolltofixed-min';

// Carico in maniera lazy le immagini
const lazy = new LazyLoad({
    elements_selector: '.lazy'
});

emitter.on('list:updated', () => lazy.update());

window.setupByeModal = function() {
    ouibounce(false, {
        aggressive: false,
        callback() {
            if (favoritesHelper.getAll().length >= 1) {
                $('#bye-modal').modal('toggle');
            }
        }
    });
};

window.setupByeModalLanding = function() {
    ouibounce(false, {
        aggressive: true,
        callback() {
            if (landingHelper.getSelected().length >= 1) {
                $('#bye-modal').modal('toggle');
            }
        }
    });
};

// Sticky elements
$(window).on('load resize orientationchange', () => {
    // let fixer = new Fixer();
    if (window.innerWidth > 992) {
        if ($('.js--fixed-el').length > 0) {
            new Sticky('.js--fixed-el');
        }
    }
});

$(window).on('load', () => {
    $('#loading_screen').css('display', 'none');
});

// Bottone to top
$(window).on('load scroll resize', () => {
    // Le aggiorno dopo questi eventi per caricare le immagini on load
    lazy.update();

    const scrollpos =
        document.documentElement.scrollTop || document.body.scrollTop;
    const btn = $('.js--to-top');
    const elem = $('body.u--trasp-header');
    //console.log(scrollpos);
    if (scrollpos > 100) {
        btn.show();
        elem.removeClass('g--header-style-white');
    } else {
        btn.hide();
        elem.addClass('g--header-style-white');
    }
});

$(document).ready(() => {
    const $byeModal = $('#bye-modal');
    const {
        forms: { compila_tutti_campi }
    } = definition;

    // Gestione scroll
    if (location.hash) {
        // do the test straight away
        window.scrollTo(0, 0); // execute it straight away

        setTimeout(function() {
            window.scrollTo(0, 0); // run it a bit later also for browser compatibility
        }, 1);
    }

    const hash = window.location.hash.replace('#', '');

    if (hash) {
        const navOffset = $('.l--navbar-cnt').height() + 8;
        //console.log(navOffset);
        jsGoTo(hash, navOffset);
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('.js--scrollto').on('click', e => {
        const $el = $(e.target).closest('.js--scrollto');
        const target = $el.attr('data-target');
        const offset = $el.attr('data-offset');
        // console.warn(target, offset);
        jsGoTo(target, offset);
    });

    $('.js--slider').each(function() {
        generateSlider($(this), 0);
    });

    // Chiudo bye modal al click del bottone apposito
    $byeModal.on('click', '.j--close-bye-modal', () => {
        $byeModal.modal('hide');
    });

    // Chiudo bye modal al click del tasto mostra form landing page
    $byeModal.on('click', '.js--show-form', () => {
        $byeModal.modal('hide');
    });

    // Select anno archivio
    $('.archivio-selects select').change(function() {
        const url = $(this).val();

        if (url !== '') {
            document.location = url;
        }
    });

    // Tasto cerca offerte homepage
    $('.btn-search-offers').on('click', e => {
        e.preventDefault();

        const formData = $('#search-offers-forms').serializeArray();

        const data = {};

        formData.forEach(input => {
            data[input.name] = input.value;
        });

        //Forzata località a Monte Bondone nel search in home
        data.localita =
            window.location.origin + '/trento/offerte-monte-bondone.php';

        // data.arrivo = "2020-03-18";
        // data.partenza = "2020-03-25";

        // console.log('prima del check', data)

        if (
            // data.localita === '' ||
            data.arrivo === '' ||
            data.partenza === ''
        ) {
            // alert(compila_tutti_campi);
            // if (data.localita === '') {
            //     $("#search-offers-forms input[name='localita']").addClass(
            //         'validationErrorBg'
            //     );
            // }
            if (data.arrivo === '') {
                $("#search-offers-forms input[name='arrivo']").addClass(
                    'validationErrorBg'
                );
            }
            if (data.partenza === '') {
                $("#search-offers-forms input[name='partenza']").addClass(
                    'validationErrorBg'
                );
            }
            return false;
        }

        api.setSessionDates(data).then(() => {
            const { localita } = data;

            window.location = localita;
        });
    });

    // Dropdown elenco preferiti
    const $divDropDown = $('#hotel-favourites-dropdown');
    const $btnDropdown = $('.btn-contatta-hotel');

    const closeDropDown = function() {
        $divDropDown.hide();
    };

    let timer = setTimeout(closeDropDown, 300);

    if (window.innerWidth > 992) {
        $btnDropdown.mouseenter(() => {
            if (favoritesHelper.getAll().length === 0) {
                return false;
            }

            clearTimeout(timer);

            if (!$divDropDown.is(':visible')) {
                $divDropDown.show();
            }
        });

        $divDropDown.mouseleave(() => {
            if ($divDropDown.is(':visible')) {
                timer = setTimeout(closeDropDown, 300);
            }
        });

        $('body').click(event => {
            // ???
            $divDropDown.not($divDropDown.has(event.target)).hide();
        });
    }
});

$(document).ready(() => {
    const {
        forms: { compila_tutti_campi }
    } = definition;

    $(document).on('click', '.btn_subscribe_newsletter', function(e) {
        e.preventDefault();

        const formId = $(this).data('form');
        const $form = $(`#${formId}`);

        if (!newsletterHelper.validate($form)) {
            alert(compila_tutti_campi);
            return false;
        }

        newsletterHelper.subscribe($form);
    });

    $('.btn_unsubscribe_newsletter').click(function(e) {
        e.preventDefault();

        const formId = $(this).data('form');
        const $form = $(`#${formId}`);

        newsletterHelper.unsubscribe($form);
    });

    $('.l--equal-h').each(function() {
        var maxHeight = 0;
        $(this)
            .children('div')
            .each(function() {
                //console.log($(this))
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                    //console.log('Altezza prima '+maxHeight);
                }
                //
            });
        //console.log('Altezza dopo '+maxHeight/2);
        $(this)
            .children('div')
            .height(maxHeight);
        $(this)
            .children('div.l--height-half')
            .height(maxHeight / 2);
    });

    $('.l--equal-h-2 > div').css('height', 'auto');

    $('.l--equal-h-2').each(function() {
        var maxHeight = 0;
        $(this)
            .children('div')
            .each(function() {
                //console.log($(this))
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
                //$(this).height(maxHeight)
            });
        $(this)
            .children('div')
            .height(maxHeight);
        $(this)
            .children('div.l--height-half')
            .height(maxHeight / 2);
    });

    $('.js--fixing-header').each(function() {
        var $vspace = $(this).attr('data-vertical-space');
        //console.log('Dentro .js--fixing-obj: '+$vspace);
        fixElements($(this), $vspace);
    });

    $('.telefonata.tel_1').click(function() {
        ga('send', 'event', 'Telefono', 'Chiamata', '+39 0461 1780777');
        console.log('tel_1');
    });
});

window.addEventListener('load', () => {
    // Slider gallery
    $('.js--light-slider').each(function() {
        const $el = $(this),
            $item = $el.attr('data-item'),
            $itemSm = $el.attr('data-item-sm'),
            $itemXs = $el.attr('data-item-xs'),
            $width = $el.attr('data-width'),
            $thumb = $el.attr('data-thumb-n');
        // console.log('$thumb' + $thumb);
        $($el).lightSlider({
            gallery: $thumb > 1,
            item: $item > 1 ? $item : 1,
            autoWidth: $width === 'auto',
            slideWidth: $width > 1 ? $width : false,
            loop: true,
            thumbItem: $thumb < 1 ? $thumb : 12,
            slideMargin: 0,
            enableDrag: true,
            currentPagerPosition: 'left',
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: $itemSm < 1 ? $itemSm : $item,
                        slideMove: 1,
                        slideMargin: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: $itemXs < 1 ? $itemXs : $item,
                        slideMove: 1
                    }
                }
            ]
        });
    });
});

window.addEventListener('load', () => {
    // Slider gallery
    $('.js--light-carousel').each(function() {
        const $el = $(this);
        // $item = $el.attr('data-item'),
        // $itemSm = $el.attr('data-item-sm'),
        // $itemXs = $el.attr('data-item-xs'),
        // $width = $el.attr('data-width'),
        // $thumb = $el.attr('data-thumb-n');
        // console.log('$thumb' + $thumb);

        $($el).lightSlider({
            item: 3,
            auto: true,
            loop: true,
            slideMove: 3,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 1200,
            pause: 4500,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: 2,
                        slideMove: 2,
                        slideMargin: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: 1,
                        slideMove: 1
                    }
                }
            ]
        });
        // $($el).lightSlider({
        //     gallery: $thumb > 1,
        //     item: $item > 1 ? $item : 1,
        //     autoWidth: $width === 'auto',
        //     slideWidth: $width > 1 ? $width : false,
        //     loop: true,
        //     thumbItem: $thumb < 1 ? $thumb : 12,
        //     slideMargin: 0,
        //     enableDrag: true,
        //     currentPagerPosition: 'left',
        //     adaptiveHeight: true,
        //     responsive: [
        //         {
        //             breakpoint: 800,
        //             settings: {
        //                 item: $itemSm < 1 ? $itemSm : $item,
        //                 slideMove: 1,
        //                 slideMargin: 6
        //             }
        //         },
        //         {
        //             breakpoint: 480,
        //             settings: {
        //                 item: $itemXs < 1 ? $itemXs : $item,
        //                 slideMove: 1
        //             }
        //         }
        //     ]
        // });
    });
});

window.showHints = async function() {
    const {
        general: { avanti, indietro },
        hints: { hint_general_step_1, hint_general_step_2 }
    } = definition;

    const { CACHE_DEFAULT_TAG } = process.env;

    const deviceWidth = $(window).innerWidth();

    let hopPosition, secondStepTarget, firstStepTarget;

    deviceWidth < 992 ? (hopPosition = 'top') : (hopPosition = 'bottom');

    deviceWidth < 992
        ? (secondStepTarget = '.j--tour-2-bottom')
        : (secondStepTarget = '.j--tour-2');

    deviceWidth < 992
        ? (firstStepTarget = [
              '.item-add-to-favorites-small',
              '.item-add-to-favorites'
          ])
        : (firstStepTarget = '.item-add-to-favorites');

    let tour = {
        id: 'tour-preferiti',
        i18n: {
            nextBtn: avanti,
            prevBtn: indietro,
            doneBtn: 'Ok'
        },
        bubbleWidth: 200,
        steps: [
            {
                title: hint_general_step_1,
                target: firstStepTarget,
                placement: 'bottom',
                xOffset: -190,
                arrowOffset: 190
            },
            {
                title: hint_general_step_2,
                target: secondStepTarget,
                placement: hopPosition,
                arrowOffset: 'top',
                xOffset: 15
            }
        ],
        onEnd() {
            // console.warn('TOUR ENDED!');
            jsGoTo('u--top', '0');
            cookieHelper.set(`${CACHE_DEFAULT_TAG}_tour_base`, true, {
                expires: 2592000
            });
        }
    };

    const $searchItem = $('.search-item');

    window.addEventListener('load', () => {
        const cookieStatus = cookieHelper.get(`${CACHE_DEFAULT_TAG}_tour_base`);

        // Non animo gli oggetti gia presenti nella pagina on load
        $searchItem.each(function(i, e) {
            if ($(e).is(':in-viewport')) {
                $(e).addClass('not-animate');
            }
        });

        // Animo gli elementi presenti man mano che diventano visibili
        $searchItem.inViewport(function(px) {
            if (px) {
                if (!$(this).hasClass('not-animate')) {
                    $(this).addClass('animated fadeInDown');
                }
            }
        });

        // console.warn(cookieStatus);

        if (typeof cookieStatus === 'undefined') {
            hopscotch.startTour(tour, 0);
        }
    });
};

function fixElements(elem, vspace) {
    //console.log('Dentro fixElements: '+vspace);
    var fixedObjs = elem;

    fixedObjs.each(function(i) {
        var spaceV = vspace != undefined ? parseInt(vspace) : 10;
        var fixedObj = $(fixedObjs[i]);
        var next = fixedObjs[i + 1];
        //console.log(fixedObj);
        fixedObjs.parent('[class*="col-"]').css('position', 'static');
        fixedObjs
            .parent()
            .parent('[class*="col-"]')
            .css('position', 'static');
        fixedObjs
            .parent()
            .parent()
            .parent('[class*="col-"]')
            .css('position', 'static');
        fixedObj.scrollToFixed({
            marginTop: $('.js--fixing').outerHeight(true) + parseInt(spaceV),
            limit: function() {
                var limit = 0;
                if (next) {
                    limit =
                        $(next).offset().top -
                        $(this).outerHeight(true) -
                        parseInt(spaceV);
                } else {
                    limit =
                        $('.js--fixed-limit').offset().top -
                        $(this).outerHeight(true);
                }
                return limit;
            },
            zIndex: 999
        });
    });
}

function createDatepicker(fromEl, toEl, write, lang) {
    var $from = $(fromEl),
        $to = $(toEl),
        $lang = lang,
        $today = new Date().toISOString().slice(0, 10),
        $startToDate = new Date();
    //console.log('startToDate', $startToDate)

    $startToDate.setDate($startToDate.getDate() + 3);
    //console.log('$startToDate: '+ $startToDate);
    var minTo = $startToDate;
    var minDateArriveCookie = getCookie('data_arrivo');
    var minDateLeaveCookie = getCookie('data_partenza');

    //Campo Arrivo
    var fromPicker = $from.flatpickr({
        minDate: new Date(),
        defaultDate:
            minDateArriveCookie != null ? minDateArriveCookie : new Date(),
        locale: $lang,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        onReady: function(selectedDates, dateStr, instance) {
            $('[name=data_arrivo]').val(dateStr);
        },
        onChange: function(selectedDates, dateStr, instance) {
            // On change setto la data, altrimenti nel mobile nativo non cambia
            toPicker.setDate(dateStr);
            //console.log('fromPicker onChange: '+dateStr);
            // On change setto la data di partenza uguale a quella di arrivo
            toPicker.set('minDate', dateStr);
            $('[name=data_arrivo]').val(dateStr);
            setCookie('data_arrivo', dateStr, 2);
            // cookieHelper.set(`data_arrivo`, dateStr, {
            //     expires: 2592000
            // });
            writeInput($from, dateStr);
            writeInput($to, dateStr);
        }
    });
    //Campo Partenza
    var toPicker = $to.flatpickr({
        minDate:
            minDateArriveCookie != null ? minDateArriveCookie : $startToDate,
        defaultDate:
            minDateLeaveCookie != null ? minDateLeaveCookie : $startToDate,
        altInput: true,
        locale: $lang,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        onReady: function(selectedDates, dateStr, instance) {
            $('[name=data_partenza]').val(dateStr);
        },
        onChange: function(selectedDates, dateStr, instance) {
            //On change setto la data, altrimenti nel mobile nativo non cambia
            toPicker.setDate(dateStr);
            $('[name=data_partenza]').val(dateStr);

            setCookie('data_partenza', dateStr, 2);
            // cookieHelper.set('data_partenza', dateStr.replace('"', ''), {
            //     expires: 2592000
            // });
            console.log('On ready: ' + dateStr);
            writeInput($to, dateStr);
        }
    });
    var $data_arrivo =
        minDateArriveCookie != null ? minDateArriveCookie : $today;
    var $data_partenza =
        minDateLeaveCookie != null
            ? minDateLeaveCookie
            : $startToDate.toISOString().slice(0, 10);
    //console.log('On ready: ' + $data_partenza);
    writeInput($from, $data_arrivo);
    writeInput($to, $data_partenza);
}

// Creo i due datepicker
createDatepicker('#fromDate', '#toDate', true, 'it');

function writeInput($input, $date) {
    //Scrivo i valori dei campi dentro i div per la grafica del calendario 2018
    var $arrdate = $date.split('-');
    //console.log('writeInput() input:' + $input + ' date: ' + $date);
    $input
        .parent()
        .find('.g--dp-d')
        .html($arrdate[2]);
    $input
        .parent()
        .find('.g--dp-m')
        .html($arrdate[1]);
    $input
        .parent()
        .find('.g--dp-y')
        .html($arrdate[0]);
}

function setCookie(name, value, days) {
    //console.log(days);
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
        //console.log(expires);
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function generateSlider(el, start) {
    var $arrows = el.attr('data-arrows');
    var $dots = el.attr('data-dots');
    var $slideToShow = el.attr('data-slidesToShow');
    var $slideToShowXS = el.attr('data-slidesToShowXS');
    var $slideToShowSM = el.attr('data-slidesToShowSM');
    var $speed = el.attr('data-speed');
    var $autoplay = el.attr('data-autoplay');
    var $autoplayspeed = el.attr('data-autoplay-speed');
    var $fade = el.attr('data-fade');

    var options = {};

    options.arrows = $arrows == 'true' ? true : false;
    options.dots = $dots == 'true' ? true : false;
    options.fade = $fade == 'true' ? true : false;
    options.slidesToShow = $slideToShow == '' ? 1 : $slideToShow;
    options.speed = $speed == '' ? 800 : $speed;
    options.autoplay = $autoplay == 'true' ? true : false;
    options.easing = 'easeInOutQuint';
    options.autoplaySpeed = $autoplayspeed == '' ? 7000 : $autoplayspeed;
    options.touchThreshold = 100;
    options.initialSlide = start;

    options.responsive = [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: $slideToShowSM == '' ? 1 : $slideToShowSM,
                slidesToScroll: 1,
                infinite: true,
                dots: $dots == 'true' ? true : false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: $slideToShowSM,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                infinite: true,
                dots: $dots == 'true' ? true : false,
                easing: 'linear',
                centerMode: false,
                centerPadding: '20px',
                slidesToShow: $slideToShowXS,
                slidesToScroll: 1
            }
        }
    ];
    //console.log(options);
    $(el).slick(options);
}

// function formatDate(date) {
//     const parts = date.split('-');

//     return `${parts[2]}/${parts[1]}/${parts[0]}`;
// }

// window.syncHotelDoor = function(formId, language = 'it') {
//     const form = document.getElementById(formId);

//     const langCode = {
//         it: 'it-IT',
//         en: 'en-GB',
//         fr: 'fr-FR',
//         de: 'de-DE'
//     };

//     const apiCode = {
//         '792': 'f7d0f662-6c63-4e55-b0b6-69251058a9b9', // Biondi Hotels
//         '1393': '874bf6db-8e25-4d14-a55e-8831db5b0d45', // Nettuno
//         '904': '2d3fc0d8-ee05-4e00-aa17-c4a3f5b9f53c', // Valverde
//         '1040': '2d3fc0d8-ee05-4e00-aa17-c4a3f5b9f53c', // Valverde
//         '835': 'de5fa5d2-2e16-4ead-b8b7-fd2b09560759', // Sport
//         '1220': 'de5fa5d2-2e16-4ead-b8b7-fd2b09560759' // Sport
//     };

//     const parsed = serialize(form, { hash: true, empty: true });

//     const validIds = _.keys(apiCode);

//     const toSend = _.intersection(parsed.codici_hotel, validIds);

//     if (toSend.length > 0) {
//         const guest = {
//             Language: langCode[language],
//             Email: parsed.email,
//             Source: document.location.origin,
//             Notes: parsed.testo,
//             First: parsed.nome,
//             Last: parsed.cognome,
//             Privacy: 1
//         };

//         let rooms = [];

//         parsed.camera.forEach(r => {
//             if (r.n_adulti !== '') {
//                 const roomData = {
//                     Adults: r.n_adulti,
//                     ChildrenAges: r.eta_bambini.filter(e => e !== '0'),
//                     MealPlan: r.trattamento
//                 };

//                 rooms.push(roomData);
//             }
//         });

//         const id = Date.now() + Math.random();
//         const arrival = formatDate(parsed.data_arrivo);
//         const departure = formatDate(parsed.data_partenza);
//         const requestDate = format('dd/MM/yyyy hh:mm:ss', new Date());

//         _.each(toSend, hotelId => {
//             const payload = {
//                 ApiKey: apiCode[hotelId],
//                 // aZPmeUW827Ju
//                 Reservations: [
//                     {
//                         Conf: id,
//                         External: id,
//                         Arrival: arrival,
//                         Departure: departure,
//                         RequestDateTime: requestDate,
//                         Referer: document.location.origin,
//                         Guest: guest,
//                         Rooms: rooms
//                     }
//                 ]
//             };

//             console.warn(payload);

//             fetch('https://api.hoteldoor.it/reservations2', {
//                 body: JSON.stringify(payload),
//                 cache: 'default',
//                 headers: {
//                     'ocp-apim-subscription-key':
//                         '681d5379ad8348b88edf67ba611cbec9', // TODO: dinamica per portale
//                     'content-type': 'application/json'
//                 },
//                 mode: 'cors',
//                 method: 'POST'
//             })
//                 .then(res => res.json())
//                 .then(res => console.warn(res))
//                 .catch(err => console.error(err));
//         });
//     }

//     // return parsed;
// };

// Form generico
$('#form-generico').submit(function(event) {
    event.preventDefault();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.alert').removeClass('d-none');
    $.ajax({
        url: '/invio-smtp',
        type: 'post',
        data: $('#form-generico').serialize(), // Remember that you need to have your csrf token included
        //dataType: 'json',
        contentType: 'application/x-www-form-urlencoded',
        success: function(_response) {
            $('.alert')
                .removeClass('alert-danger')
                .addClass('alert-success');
            $('.alert').html(
                'Messaggio inviato correttamente! Verrai ricontattato il prima possibile!'
            );
            $('#form-generico')[0].reset();
        },
        error: function(_response) {
            $('.alert')
                .removeClass('alert-success')
                .addClass('alert-danger');
            $('.alert').html('Compila tutti i campi richiesti!');
        }
    });
});

// Sidebar servizi a scomparsa
$(document).ready(function() {
    $('#dismiss, .overlay-sb, #email-dismiss').on('click', function() {
        // hide sidebar
        $('#sidebar-dx').removeClass('active');
        // hide overlay
        $('.overlay-sb').removeClass('active');
    });

    $('#sidebarCollapse').on('click', function() {
        // open sidebar
        $('#sidebar-dx').addClass('active');
        // fade in the overlay
        $('.overlay-sb').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
});

// Pulsanti laterali dx
$(document).ready(function() {
    $('#pulsantiCollapse').on('click', function() {
        $('#pulsantiSidebar').toggleClass('active');
        $('#pulsantiCollapse .fas').toggleClass(
            'fa-chevron-left',
            'fa-chevron-right'
        );
        $('#pulsantiCollapse .fas').toggleClass(
            'fa-chevron-right',
            'fa-chevron-left'
        );
    });
});
