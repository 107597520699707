import favoritesHelper from './../helpers/favorites-helper';
import landingHelper from './../helpers/landing-helper';

// Funzione per "muovere" il blocco hotel nel bottone dei preferiti
function moveBlock($el) {
    const $div = document.createElement('div');
    let $contenitoreDestinazione = $('.g--ico-preferiti');

    if ($($contenitoreDestinazione[0]).is(':hidden')) {
        $contenitoreDestinazione = $($contenitoreDestinazione[1]);
    }

    $div.setAttribute('id', 'muovi');
    $($div).addClass('u--transition');
    document.body.appendChild($div);
    //console.log($contenitoreDestinazione.is(':visible'));
    const $sposta = $el.offset();
    // console.log($el.offset());
    $el.closest('.js--hotel-cnt')
        .clone()
        .appendTo($div);

    $($div).css({
        position: 'absolute',
        zIndex: '100',
        overflow: 'hidden',
        top: $sposta.top,
        left: $sposta.left,
        opacity: '1',
        width: '300px',
        height: '300px'
    });

    const $destinazione = $contenitoreDestinazione.is(':visible')
        ? $contenitoreDestinazione
        : $('.js--show-form');

    const $posizione = $destinazione.offset();

    $($div).css({
        left: $posizione.left,
        top: $posizione.top,
        overflow: 'hidden',
        opacity: '.05',
        width: '100px',
        height: '100px'
    });

    $($contenitoreDestinazione).css({
        transform: 'scale(1.4)'
    });

    setTimeout(function() {
        $($div).remove();
        $($contenitoreDestinazione).css({
            transform: 'scale(1)'
        });
    }, 400);
}

$(document).ready(() => {
    // Aggiunge/rimuove un hotel dai preferiti
    $('#list-root').on('click', '.item-add-to-favorites', e => {
        const id = $(e.target)
            .closest('.search-item')
            .data('id');

        // console.log('Prima di if');
        //console.log($(e.target));

        if (!$(e.target).hasClass('item-added')) {
            const $el = $(e.target)
                .parent()
                .parent()
                .parent()
                .find('.js--hotel-cnt');
            //console.log('dentro if');
            //console.log($(e.target));

            moveBlock($el);
        }

        favoritesHelper.toggle(parseInt(id));
    });

    $('#list-root').on('click', '.item-add-to-landing', e => {
        const id = $(e.target)
            .closest('.search-item')
            .data('id');

        if (!$(e.target).hasClass('item-added')) {
            const $el = $(e.target)
                .closest('.l--hotel-list')
                .find('.js--hotel-cnt');
            //console.log('dentro if');
            //console.log($(e.target));

            moveBlock($el);
        }

        landingHelper.toggle(parseInt(id));
    });

    $('#favourites-items-list').on('click', '.remove-favourite', e => {
        const $el = $(e.target).closest('.remove-favourite');
        const id = $el.data('id');

        favoritesHelper.remove(id, favoritesHelper.getAll().length);

        $el.closest('.j--favourites-item').remove();

        window.aoForm.removeRecipient(id);
    });
});
